import React, { ReactElement, useEffect, useRef, useState } from 'react';
import {
  createStyles,
  StyleRules,
  Theme,
  Tooltip,
  WithStyles,
  withStyles,
} from '@material-ui/core';
import { Carousel } from 'antd';
import { navigate } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';

import specialData from '../../../utils/special';

import '../../../styles/ant-Diy-style.scss';

const styles = (theme: Theme): StyleRules =>
  createStyles({
    itemText: {
      fontWeight: 'lighter',
      padding: '3px',
      maxWidth: '5rem',
      fontSize: '0.75rem',
      marginLeft: '10px',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      '&:hover': {
        color: '#ffffff',
        backgroundColor: '#5a5a8b',
      },
    },
    childrenItemText: {
      display: 'flex',
      marginLeft: '15px',
    },
    filterItem: {
      borderRadius: '5px',
      fontWeight: 'bold',
      width: '100%',
      height: '383px',
      color: '#333',
      background: '#fff',
      overflow: 'auto',
    },
    menuSpecial: {
      marginLeft: '0',
      position: 'relative',

      [theme.breakpoints.up('md')]: {
        width: '365px',
      },
      [theme.breakpoints.down('sm')]: {
        display: 'none',
      },
    },
    rightBanner: {
      marginLeft: '1rem',
      overflow: 'hidden',
      borderRadius: '5px',
      width: '900px',
      height: '383px',

      [theme.breakpoints.down('sm')]: {
        width: '336px',
        height: '143px',
      },
    },
    borderCss: {
      borderRadius: '5px',
    },
    iconSize: {
      height: '1rem',
      width: '1rem',
    },
    bannerImg: {
      width: '100%',
      height: '100%',
    },
    itemLabel: {
      width: '100%',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      color: '#9b9bbe',
      cursor: 'pointer',
      display: 'flex',
      alignItems: 'center',
      padding: '10px',
      flexWrap: 'nowrap',
      transition: 'all 0.3s ease-in-out',
      '&:hover': {
        boxShadow: '0 4px 16px 0 rgba(153,153,153,0.2)',
        color: '#ffffff',
        background: '#9b9bbe',
      },
      '& .hover': {
        boxShadow: '0 4px 16px 0 rgba(153,153,153,0.2)',
      },
    },
    itemLabelHover: {
      textAlign: 'center',
      padding: '1rem',
      borderRadius: '1rem',
      boxShadow: '0 4px 16px 0 rgba(153,153,153,0.2)',
      transition: 'all 0.3s ease-in-out',
    },
    filterItemContent: {
      position: 'absolute',
      top: '4rem',
      height: '10rem',
      borderRadius: '1rem',
      padding: '0.5rem 2rem',
      background: '#fff',
      boxShadow: '0px 8px 20px 0px rgba(51, 51, 51, 0.1)',
      zIndex: 999,
      width: '100%',
    },
    childContainer: {
      padding: '1rem',
      maxHeight: '60vh',
      width: '50vw',
      display: 'flex',
      overflow: 'auto',
      flexWrap: 'wrap',
      background: '#ffffff',
    },
    child: {
      padding: '0.5rem 1rem',
      color: '#333',
      cursor: 'pointer',
      '&:hover': {
        color: '#ffff',
        fontWeight: 'bold',
        background: '#9b9bbe',
      },
    },
    childTitle: {
      margin: '0.5rem',
      color: '#9b9bbe',
      fontWeight: 'bold',
      fontSize: '1rem',
    },
    childActive: {
      color: '#242f65',
      fontWeight: 'bold',
    },
    filterItemLabel: {
      fontWeight: 600,
      padding: '0.5rem 0',
      minWidth: '3rem',
    },
    filterBottom: {
      display: 'flex',
      justifyContent: 'flex-end',
    },
    searchContainer: {
      width: '100%',
      display: 'flex',
      padding: '2rem 0',
      '& .ant-input-group .ant-input-group-addon': {
        background: 'unset !important',
        border: 'none !important',
        padding: '0 !important',
      },
      '& .ant-input': {
        height: '3rem !important',
      },
    },
    searchButton: {
      marginLeft: '1rem',
    },
    scrollBtn: {
      padding: '0',
      position: 'absolute',
      bottom: 15,
      right: 15,
      width: '23px',
    },
    tooltipSet: {
      padding: '0',
      backgroundColor: '#ffffff',
      fontSize: '0.75rem',
      marginTop: '64px',
    },
    contentImg: {
      borderRadius: '5px',
      width: '900px',
      height: '383px',
      [theme.breakpoints.down('sm')]: {
        width: '336px',
        height: '143px',
      },
    },
    itemImg: {
      cursor: 'pointer',
      width: '900px',
      height: '383px',
      borderRadius: '5px',
      [theme.breakpoints.down('sm')]: {
        width: '336px',
        height: '143px',
      },
      objectFit: 'cover',
    },
    container: {
      overflow: 'hidden',
      marginBottom: '2rem',
      display: 'flex',
      justifyContent: 'space-between',
      width: '100%',
      [theme.breakpoints.up('md')]: {
        width: '1280px',
        padding: '0 1rem',
      },
    },
    'slick-next': {
      background: 'black !important',
      borderRadius: '50% !important',
    },
  });

interface ProfessionalItem {
  label: string;
  value: string;
  children?: ProfessionalItem[];
  showChildren?: boolean;
}

const ConsultantViewHeader = ({ classes }: Props): ReactElement => {
  const [renderProfessionalList, setRenderProfessionalList] = useState<ProfessionalItem[]>([]);

  const [childrenList, setChildrenList] = useState<ProfessionalItem[]>([]);
  const refs = new Array(24).fill(null).map(() => useRef<HTMLSpanElement>(null));
  const refsBox = new Array(24).fill(null).map(() => useRef<HTMLDivElement>(null));

  const onMouseEnter = (item: ProfessionalItem, event: React.MouseEvent<HTMLDivElement>) => {
    const findIndex = [...specialData].findIndex(renderItem => renderItem.value === item.value);
    const childrenListData = specialData[findIndex].children || [];
    setChildrenList(childrenListData);
    event.stopPropagation();
    event.nativeEvent.stopPropagation();
  };

  const handleView = (id?: number) => {
    if (!id) return;
    navigate(`/project/view/#${id}`);
  };

  const setList = () => {
    const list = specialData.map(item => {
      return {
        ...item,
        showChildren: false,
      };
    });
    setRenderProfessionalList(list);
  };
  const childItemDom = (
    <div className={classes.childContainer}>
      {childrenList.map((child, index) => {
        return (
          <div key={index}>
            <div className={classes.childTitle}>{child.label}</div>
            {child.children?.map((item, itemIndex) => (
              <div
                key={itemIndex}
                className={classes.child}
                onClick={() => {
                  navigate(`/consultant/publicBiddingProjects`);
                }}
              >
                {item.label}
              </div>
            ))}
          </div>
        );
      })}
    </div>
  );

  useEffect(() => {
    setList();
  }, []);
  useEffect(() => {
    if (refsBox) {
      for (let i = 0; i < refsBox.length; i++) {
        if (refsBox[i].current?.offsetWidth) {
          const WidthRemaining = refsBox[i].current.offsetWidth - 35 - refs[i].current.offsetWidth;
          let sumItemWidth = 0;
          for (let j = 0; j < 6; j++) {
            const element = document.getElementById(`span-${i}-${j}`);
            if (element) {
              sumItemWidth += element.offsetWidth + 10;
              if (sumItemWidth > WidthRemaining) {
                element.style.display = 'none';
              }
            }
          }
        }
      }
    }
  }, [refsBox]);

  return (
    <div className={classes.container}>
      <div className={classes.menuSpecial}>
        <div className={classes.filterItem}>
          {renderProfessionalList.map((item, index) => {
            return (
              <Tooltip
                key={item.value}
                title={childItemDom}
                interactive={true}
                placement="right"
                classes={{ tooltip: classes.tooltipSet }}
              >
                <div
                  ref={refsBox[index]}
                  className={classes.itemLabel}
                  onMouseEnter={e => onMouseEnter(item, e)}
                >
                  <span ref={refs[index]}>{item.label}</span>
                  <div className={classes.childrenItemText}>
                    {specialData[
                      specialData.findIndex(renderItem => renderItem.value === item.value)
                    ].children[0].children.map((itemText, itemIndex) => {
                      if (itemIndex > 5) return;
                      const element = (
                        <span
                          id={`span-${index}-${itemIndex}`}
                          className={classes.itemText}
                          key={itemIndex}
                          onClick={() => {
                            navigate(`/consultant/publicBiddingProjects`);
                          }}
                        >
                          {itemText.label}
                        </span>
                      );
                      return element;
                    })}
                  </div>
                </div>
              </Tooltip>
            );
          })}
        </div>
      </div>
      <div className={classes.rightBanner}>
        <Carousel arrows={true} autoplay draggable={true}>
          <div
            className={classes.contentImg}
            onClick={() => {
              handleView(436);
            }}
          >
            <StaticImage
              src="../../../images/f1.jpg"
              alt="A dinosaur"
              className={classes.itemImg}
            />
          </div>
          <div
            className={classes.contentImg}
            onClick={() => {
              handleView(428);
            }}
          >
            <StaticImage
              src="../../../images/f2.png"
              alt="A dinosaur"
              className={classes.itemImg}
            />
          </div>
          <div
            className={classes.contentImg}
            onClick={() => {
              handleView(420);
            }}
          >
            <StaticImage
              src="../../../images/f3.png"
              alt="A dinosaur"
              className={classes.itemImg}
            />
          </div>
          <div
            className={classes.contentImg}
            onClick={() => {
              handleView(437);
            }}
          >
            <StaticImage
              src="../../../images/ft4.jpg"
              alt="A dinosaur"
              className={classes.itemImg}
            />
          </div>
          <div
            className={classes.contentImg}
            onClick={() => {
              handleView(447);
            }}
          >
            <StaticImage
              src="../../../images/ft5.png"
              alt="A dinosaur"
              className={classes.itemImg}
            />
          </div>
          <div
            className={classes.contentImg}
            onClick={() => {
              handleView(449);
            }}
          >
            <StaticImage
              src="../../../images/ft6.png"
              alt="A dinosaur"
              className={classes.itemImg}
            />
          </div>
        </Carousel>
      </div>
    </div>
  );
};

export interface Props extends WithStyles<typeof styles> {
  className?: string;
}

export default withStyles(styles)(ConsultantViewHeader);
