import React, { ReactElement, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Button, createStyles, StyleRules, Theme, WithStyles, withStyles } from '@material-ui/core';
import { navigate } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';

import {
  anonymousGetLastCreatedProjectNoPicturesRequest,
  anonymousGetLastCreatedProjectRequest,
  anonymousGetLastUpdatedProjectNoPicturesRequest,
  anonymousGetLastUpdatedProjectRequest,
  consultantGetAllProjectNoPicturesRequest,
  consultantGetAllProjectRequest,
} from '../../../actions/projectRequestAction';
import picture from '../../../images/defaultImg.png';
import { FUserTypeEnum, userTypeKnownAction } from '../../../reducers/userReducer';
import Industry from '../../../utils/industry';
import { industryFilterHandle } from '../../../utils/industryFilter';
import { moneyUnit } from '../../../utils/projectView';
import { searchLabelsByValue } from '../../../utils/searchUtils';
import Professional from '../../../utils/special';
import { MatchArea } from '../../../utils/util';

const styles = (theme: Theme): StyleRules =>
  createStyles({
    projectContainer: {
      width: '100%',
      [theme.breakpoints.up('md')]: {
        width: '1280px',
      },
    },
    contentContainer: {
      width: '100%',
      marginBottom: '2rem',
    },
    titleContainer: {
      paddingLeft: '1rem',
    },
    titleText: {
      marginLeft: '0.5rem',
      fontWeight: 'bold',
      fontSize: '1.5rem',
    },
    containerBox: {
      padding: '0 1rem',
      width: '100%',
      display: 'flex',
      justifyContent: 'space-between',
      flexWrap: 'wrap',
    },
    root: {
      marginTop: '2rem',
      width: 350,
      transition: 'all 0.3s ease-in-out',
      '&:hover': {
        boxShadow: '0px 4px 16px 0px rgba(0,0,0,0.2)',
      },
    },
    media: {
      height: 140,
    },
    projectInfoName: {
      fontSize: '1rem',
      maxWidth: '10rem',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
    bottom: {
      marginTop: '2rem',
      display: 'flex',
      justifyContent: 'center',
    },
    empty: {
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      fontWeight: 'bold',
      fontSize: '2rem',
      height: 400,
    },
    projectInfo: {
      display: 'grid',
      gridTemplateRows: '119.16px 2.5rem 5rem 2.5rem',

      margin: '1rem 0',
      [theme.breakpoints.up('md')]: {
        width: '305px',
        Height: '350px',
      },
      [theme.breakpoints.down('sm')]: {
        width: '100%',
      },
      padding: '5px',
      borderRadius: '2px',
      background: '#fff',
      cursor: 'pointer',
      transition: 'all 0.3s ease-in-out',
      '&:hover': {
        boxShadow: '0 16px 40px 0 rgba(138, 149, 158, 0.2)',
      },
      // '&:nth-of-type(3n)': {
      //   marginRight: 0,
      // },
    },
    imgStyle: {
      margin: '0',
      borderRadius: '2px',
    },
    projectInfoTop: {
      position: 'relative',
      margin: '0.5rem 0',
      padding: '0.5rem 10px',
      width: '100%',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      color: '#242f65',
    },
    projectSalary: {
      color: '#fe574a',
      textAlign: 'right',
      maxWidth: '5rem',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
    projectInfoTag: {
      display: 'flex',
      flexWrap: 'wrap',
      // whiteSpace: 'nowrap',
      width: '100%',
      fontSize: '0.75rem',
      color: '#666',
      borderRadius: '0.25rem',
      margin: '1rem',
      '&:last-of-child': {
        marginRight: 0,
      },
      // '&::-webkit-scrollbar': {
      //   width: '10px',
      // },
    },
    projectInfoBottom: {
      margin: '0.5rem 0',
      width: '100%',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    projectInfoBottomLeft: {
      fontSize: '0.75rem',
      display: 'flex',
      alignItems: 'center',
    },
    avatar: {
      marginRight: '0.5rem',
      marginBottom: 0,
      width: '1.5rem',
      height: '1.5rem',
      borderRadius: '100%',
      border: '1px solid #ccc',
    },
    companyName: {
      maxWidth: '10rem',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
    },
    projectInfoBottomRight: {
      fontSize: '0.75rem',
      color: '#333',
      fontWeight: 400,
    },

    chunk: {
      width: '5rem',
      padding: '5px',
      margin: '2px 4px',
      // background: '#f2f2f2',
      borderRadius: '2px',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
  });

const ConsultantProjectView = ({ classes }: Props): ReactElement => {
  const dispatch = useDispatch();
  dispatch(userTypeKnownAction(FUserTypeEnum.CONSULTANT));
  // const user = useSelector((appState: AppState) => appState.user);
  const [projectList, setProjectList] = useState<ProjectRequestInfo[]>([]);

  const [initData, setInitData] = useState<ProjectRequestInfo[]>([]);
  const [reallySelect, setReallySelect] = useState<ProjectRequestInfo[]>([]);
  const [lastUpdate, setLastUpdate] = useState<ProjectRequestInfo[]>([]);

  const resultIndustryView = (industry: string | CascadeArrayType[], num: number): [] => {
    const industryObj = industry ? JSON.parse(industry.toString()) : '';
    if (industryObj) {
      const industry = industryFilterHandle(industryObj, Industry);
      const newIndustry = industry.length > 2 && industry ? industry.slice(0, num) : industry;
      return newIndustry as [];
    }
    return [];
  };

  const resultProfessional = (
    professionalList: string | CascadeArrayType[],
    num: number
  ): string[] => {
    const data = JSON.parse(professionalList.toString());
    const valuesToSearch = data.map((subArray: []) => subArray[subArray.length - 1]);
    const zhi = searchLabelsByValue(Professional, valuesToSearch);
    return zhi.splice(0, num);
  };

  const fetchData = async () => {
    const data = await dispatch(consultantGetAllProjectNoPicturesRequest());
    // const publishedList = data.filter((item: ProjectRequestInfo) => item.status === 'PUBLISHED');
    setInitData([...data] as []);
    setTimeout(async () => {
      const data = await dispatch(consultantGetAllProjectRequest());
      setInitData([...data] as []);
    }, 200);
  };
  // 精选项目
  const fetchDataSelected = async () => {
    const data = await dispatch(anonymousGetLastCreatedProjectNoPicturesRequest(8));

    setReallySelect(data);

    setTimeout(async () => {
      const data = await dispatch(anonymousGetLastCreatedProjectRequest(8));
      setReallySelect(data);
    }, 200);
  };
  // 最新更新
  const fetchDataLastUpdate = async () => {
    const data = await dispatch(anonymousGetLastUpdatedProjectNoPicturesRequest(8));

    setLastUpdate(data);
    setTimeout(async () => {
      const data = await dispatch(anonymousGetLastUpdatedProjectRequest(8));
      setLastUpdate(data);
    }, 200);
  };
  // const renderList = useMemo(() => {
  //   return projectList.length > 9 ? projectList.slice(0, 9) : projectList;
  // }, [projectList]);

  const renderWorkMode = (mode: string) => {
    const workScenario = JSON.parse(mode);
    if (workScenario.remote) return true;
    else return false;
  };

  const handleView = (id?: number) => {
    if (!id) return;
    navigate(`/project/view/#${id}`);
  };

  const handleViewMore = () => {
    navigate('/consultant/publicBiddingProjects');
  };

  const renderSalary = (item: ProjectRequestInfo) => {
    return (
      <span className={classes.projectSalary}>
        {item?.remunerationInfo?.monthlySalaryMin ? (
          <span className={classes.infoValue}>
            {moneyUnit(Number(item?.remunerationInfo.monthlySalaryMin))}
            {item?.remunerationInfo?.monthlySalaryMax
              ? `-${moneyUnit(Number(item?.remunerationInfo.monthlySalaryMax))}`
              : null}
          </span>
        ) : item?.remunerationInfo?.singleSalaryMin ? (
          <span className={classes.infoValue}>
            {moneyUnit(Number(item?.remunerationInfo.singleSalaryMin))}
            {item?.remunerationInfo.singleSalaryMax
              ? `-${moneyUnit(Number(item?.remunerationInfo.singleSalaryMax))}`
              : null}
          </span>
        ) : (
          <span className={classes.infoValue}>
            {item?.remunerationInfo?.otherSalaryDescription ? '面议' : null}
          </span>
        )}
      </span>
    );
  };

  useEffect(() => {
    fetchData();
    fetchDataSelected();
    fetchDataLastUpdate();
  }, []);

  useEffect(() => {
    setProjectList(initData);
  }, [initData]);

  return (
    <div className={classes.projectContainer}>
      <div className={classes.contentContainer}>
        <div className={classes.titleContainer}>
          <span className={classes.titleText}>精选项目</span>
        </div>

        <div className={classes.containerBox}>
          {reallySelect.length > 0 ? (
            <>
              {reallySelect.map(item => {
                return (
                  <div
                    key={item.id}
                    className={classes.projectInfo}
                    onClick={() => handleView(item.id)}
                  >
                    <div>
                      {item.projectCover ? (
                        <img
                          src={item.projectCover}
                          className={classes.imgStyle}
                          width={'295'}
                          height={'125.55'}
                        />
                      ) : (
                        <StaticImage
                          className={classes.imgStyle}
                          src="../../../images/ftDefault.jpg"
                          alt="A dinosaur"
                          width={295}
                          height={125.55}
                        />
                      )}
                    </div>
                    <div className={classes.projectInfoTop}>
                      <span className={classes.projectInfoName}>{item.projectName}</span>
                      {renderSalary(item)}
                    </div>
                    <div className={classes.projectInfoTag}>
                      {item.industry &&
                        resultIndustryView(item.industry, 3).map((item, index) => {
                          return (
                            <span className={classes.chunk} key={index}>
                              {item}
                            </span>
                          );
                        })}

                      {item.professionalClass &&
                        resultProfessional(item.professionalClass, 3).map((item, index) => {
                          return (
                            <span className={classes.chunk} key={index}>
                              {item}
                            </span>
                          );
                        })}
                    </div>
                    <div className={classes.projectInfoBottom}>
                      <div className={classes.projectInfoBottomLeft}>
                        <img
                          className={classes.avatar}
                          src={item.company?.verification?.basicInfo?.avatar || picture}
                        />
                        <span className={classes.companyName}>
                          {item.company?.verification?.basicInfo?.registrationName}
                        </span>
                      </div>
                      <div className={classes.projectInfoBottomRight}>
                        {item.workScenario && renderWorkMode(item.workScenario)
                          ? '远程'
                          : MatchArea({
                              pCode: '',
                              cCode: item.workLocationCity || '1101',
                              aCode: item.workLocationDistrict || '110101',
                            })}
                      </div>
                    </div>
                  </div>
                );
              })}
            </>
          ) : (
            <div className={classes.empty}>加载中...</div>
          )}
        </div>
        {projectList.length > 9 ? (
          <div className={classes.bottom}>
            <Button size="large" variant="contained" color="primary" onClick={handleViewMore}>
              查看更多
            </Button>
          </div>
        ) : null}
      </div>
      <div className={classes.contentContainer}>
        <div className={classes.titleContainer}>
          <span className={classes.titleText}>最新项目</span>
        </div>
        <div className={classes.containerBox}>
          {lastUpdate.length > 0 ? (
            <>
              {lastUpdate.map(item => {
                return (
                  <div
                    key={item.id}
                    className={classes.projectInfo}
                    onClick={() => handleView(item.id)}
                  >
                    <div>
                      {item.projectCover ? (
                        <img
                          src={item.projectCover}
                          className={classes.imgStyle}
                          width={'295'}
                          height={'125.55'}
                        />
                      ) : (
                        <StaticImage
                          src="../../../images/ftDefault.jpg"
                          alt="A dinosaur"
                          className={classes.imgStyle}
                          width={295}
                          height={125.55}
                        />
                      )}
                    </div>
                    <div className={classes.projectInfoTop}>
                      <span className={classes.projectInfoName}>{item.projectName}</span>
                      {renderSalary(item)}
                    </div>
                    <div className={classes.projectInfoTag}>
                      {item.industry &&
                        resultIndustryView(item.industry, 3).map((item, index) => {
                          return (
                            <span className={classes.chunk} key={index}>
                              {item}
                            </span>
                          );
                        })}

                      {item.professionalClass &&
                        resultProfessional(item.professionalClass, 3).map((item, index) => {
                          return (
                            <span className={classes.chunk} key={index}>
                              {item}
                            </span>
                          );
                        })}
                    </div>
                    <div className={classes.projectInfoBottom}>
                      <div className={classes.projectInfoBottomLeft}>
                        <img
                          className={classes.avatar}
                          src={item.company?.verification?.basicInfo?.avatar || picture}
                        />
                        <span className={classes.companyName}>
                          {item.company?.verification?.basicInfo?.registrationName}
                        </span>
                      </div>
                      <div className={classes.projectInfoBottomRight}>
                        {item.workScenario && renderWorkMode(item.workScenario)
                          ? '远程'
                          : MatchArea({
                              pCode: '',
                              cCode: item.workLocationCity || '1101',
                              aCode: item.workLocationDistrict || '110101',
                            })}
                      </div>
                    </div>
                  </div>
                );
              })}
            </>
          ) : (
            <div className={classes.empty}>加载中...</div>
          )}
        </div>
        {projectList.length > 9 ? (
          <div className={classes.bottom}>
            <Button size="large" variant="contained" color="primary" onClick={handleViewMore}>
              查看更多
            </Button>
          </div>
        ) : null}
      </div>

      {/* <div className={classes.contentContainer}>
        <div className={classes.titleContainer}>
          <span className={classes.titleText}>月度热门</span>
        </div>
       <div className={classes.containerBox}>
          {renderList.length > 0 ? (
            <>
              {renderList.map(item => {
                return (
                  <div
                    key={item.id}
                    className={classes.projectInfo}
                    onClick={() => handleView(item.id)}
                  >
                    <div>
                      {item.projectCover ? (
                        <img src={item.projectCover} width={'295'} height={'125.55'} />
                      ) : (
                        <StaticImage
                          src="../../../images/ftDefault.jpg"
                          alt="A dinosaur"
                          width={295}
                          height={125.55}
                        />
                      )}
                    </div>
                    <div className={classes.projectInfoTop}>
                      <span className={classes.projectInfoName}>{item.projectName}</span>
                      {renderSalary(item)}
                    </div>
                      <div className={classes.projectInfoTag}>
                        {item.industry
                          ? resultIndustryView(item.industry, 2).map((item, index) => {
                              return (
                                <span className={classes.chunk} key={index}>
                                  {item}
                                </span>
                              );
                            })
                          : null}
                     
                        {item.professionalClass &&
                          resultProfessional(item.professionalClass, 2).map((item, index) => {
                            return (
                              <span className={classes.chunk} key={index}>
                                {item}
                              </span>
                            );
                          })}
                      </div>
                    <div className={classes.projectInfoBottom}>
                      <div className={classes.projectInfoBottomLeft}>
                        <img
                          className={classes.avatar}
                          src={item.company?.verification?.basicInfo?.avatar || picture}
                        />
                        <span>{item.company?.verification?.basicInfo?.registrationName}</span>
                      </div>
                      <div className={classes.projectInfoBottomRight}>
                        {item.workScenario && renderWorkMode(item.workScenario)
                          ? '远程'
                          : MatchArea({
                              pCode: '',
                              cCode: item.workLocationCity || '1101',
                              aCode: item.workLocationDistrict || '110101',
                            })}
                      </div>
                    </div>
                  </div>
                );
              })}
            </>
          ) : (
            <div className={classes.empty}>加载中...</div>
          )}
        </div>
        {projectList.length > 9 ? (
          <div className={classes.bottom}>
            <Button size="large" variant="contained" color="primary" onClick={handleViewMore}>
              查看更多
            </Button>
          </div>
        ) : null}
      </div> */}
    </div>
  );
};

export interface Props extends WithStyles<typeof styles> {
  className?: string;
}

export default withStyles(styles)(ConsultantProjectView);
